/* eslint no-console:0 */
// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
//
// To reference this file, add <%= javascript_pack_tag 'application' %> to the appropriate
// layout file, like app/views/layouts/application.html.erb
// import "../stylesheets/datepicker";
// import "../stylesheets/chosen";

import {getThermometerHTML, getCircleStat, renderPersonalStat} from "./personal-stat";

require("@rails/ujs").start()
require("turbolinks").start()
require("@rails/activestorage").start()
require("channels")
require('./action_text')
// require("chosen-js/")
// require("select2")
require("jquery-datetimepicker")

var debounce = require('lodash/debounce');
import './personal-stat';
import './toastr.min';
import './selectize';

require('./circle-progress');

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)

console.log('Hello World from Webpacker')

require('./direct_uploads')




jQuery(document).ready(function($) {
    if ( $('.modal-card-expired').length ) {
        setTimeout(function(){
            $('.modal-card-expired').show();
            $('.modal-card-expired-wrapper').addClass('animated');
        }, 4000);
    }

    $(document).on('click', '.new-cc-container .add-cc', function(e){
        e.preventDefault();
        const $this = $(this);
        const newCardHtml = '<div class="add-new-cc-container" style="display: none">\n' +
            '                <form method="post" id="stripe-new-cc">\n' +
            '                  <div id="card-element"></div>\n' +
            '                  <div id="card-errors" role="alert" style="display: none;"></div>\n' +
            '                  <button>Update card</button>\n' +
            '                </form>\n' +
            '              </div>';
        const stripePK = $this.data('stripe-pk');
        const customerId= $this.data('customer-id');

        $this.closest('.cc-list').find('.add-new-cc-container').remove();
        
        if ( $this.closest('.cc-container').hasClass('current-card') ) {
            $this.closest('.cc-container').removeClass('current-card')
            return true;
        }
        $('.cc-list .cc-container').each(function(i){
            $(this).removeClass('current-card');
        })
        $this.closest('.cc-container').addClass('current-card').after(newCardHtml);

        const stripe = Stripe(stripePK);
        const elements = stripe.elements();
        const style = {
            base: {
                color: '#32325d',
                lineHeight: '18px',
                fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
                fontSmoothing: 'antialiased',
                fontSize: '16px',
                '::placeholder': {
                    color: '#aab7c4'
                }
            },
            invalid: {
                color: '#fa755a',
                iconColor: '#fa755a'
            }
        };

        const card = elements.create('card', {hidePostalCode: true, style: style});

        card.mount('#card-element');


        const form = document.getElementById('stripe-new-cc');
        form.addEventListener('submit', function(event) {
            event.preventDefault();
            const container = $('.add-new-cc-container');
            showSpinner(container);

            stripe.createToken(card).then(function(result) {
                if (result.error) {
                    toasterMessage(result.error.message, 'error');
                    container.removeClass('show-spinner').find('.spinner').remove();
                } else {
                    $.ajax({
                        type: 'POST',
                        url: `/donors/add_cc`,
                        data: {
                            stripe_customer_id: customerId,
                            stripe_token_id: result.token.id,
                        },
                        success: function(data) {
                            container.removeClass('show-spinner').find('.spinner').remove();
                            silentRemoveCard($this, data);
                            toasterMessage(data.notice, 'sucess');
                        },
                        error: function(xhr) {
                            console.log(xhr);
                            container.removeClass('show-spinner').find('.spinner').remove();
                            toasterMessage(xhr.error, 'error');
                        },
                    })
                }
            }).catch(function (e) {
                console.log(e);
            });
        });

        $(this).closest('.cc-list').find('.add-new-cc-container').slideDown();
    });

    if ($('.donation-assignment-select').length) {
        $('.donation-assignment-select').each(function(index) {
            const $this = $(this);
            $this.selectize({
                plugins: ['remove_button'],
                onDropdownOpen: function() {
                    selectizeMobile($this);
                    $this.closest('li').find('.selectize-input.items .remove-single').click();
                },
           });
        });
    }

    if ($('.donation-will-recur-at').length) {

        $('.donation-will-recur-at').each(function(index){
            const dateNow = new Date();
            const dateCompletedAt = $(this).data('completed-at') ? new Date($(this).data('completed-at')) : new Date();

            const diffInTime = dateNow - dateCompletedAt;
            const diffInDays = Math.round(diffInTime / (1000 * 3600 * 24));

            $(this).datetimepicker({
                format: 'm/d/Y H:i:s',
                minDate:`+1970/01/${diffInDays > 7 ? '01' : 9 - diffInDays}`,
                step: 5,
                onSelectDate: function (ct, $i){
                    console.log(ct)
                    console.log($i)
                    $i.parent().find('.clear-date-input').show()
                },
            });
        })
    }

    var $change_avatar_image_button = $('.change-avatar-image-button');
    var $change_avatar_section = $('#change_avatar_image');
    var $change_team_goal_button = $('.change-team-goal-button');
    var $change_team_goal_section = $('#change-team-goal');
    var $invite_a_friend_button = $('.invite-a-friend-button');
    var $invite_a_friend_section = $('#invite-a-friend');

    $(document).on('click', '.become-lifeguard', function (e) {
        e.preventDefault();
        const id = $(this).data('id');
        $.ajax({
            type: 'POST',
            url: `/donors/${id}/convert`,
            success: function(data) {
                сonsole.log(data)
            },
            error: function(xhr) {
                console.log(xhr);
            },
        })
    })

    $(document).on('click', '.show-merch', function (e) {
        e.preventDefault();
    });

    $(document).on('change', '#profile_custom_avatar', function(e) {
        e.preventDefault();

        const file =  e.target.files[0];
        const fileName = file.name;
        const fileSize = file.size;
        const maxFileSize = 3000000;

        const uploadNow = $(this).parent().siblings('.avatar-upload-now');
        const accepted = $(this).attr('accept').split(', ');
        const fileType = e.target.files[0].type;

        if ( !accepted.includes(fileType) ) {
            toasterMessage('Your file type is not allowed', 'error');
            $(this).val('');
            $(this).siblings('.file-name').text('');
            uploadNow.addClass('hidden');

            return false;
        }

        if ( fileSize > maxFileSize ) {
            toasterMessage('Your file size is too big.', 'error');
            $(this).val('');
            $(this).siblings('.file-name').text('');
            uploadNow.addClass('hidden');
            return false;
        }

        $(this).siblings('.file-name').text(`selected file: ${fileName}`);
        $(this).parent().siblings('.avatar-upload-now').removeClass('hidden');
    });

    $(document).on('ajax:success', '#profile_custom_avatar_form', function(e){
        e.preventDefault();
        let data = e.detail[0];

        if ($('.sections-accordion.onboard-wizard').length) {
            const activeStepContainer = $('.accordion-item.active-step');
            let activeStepNum = activeStepContainer.data('step-num');

            if ($('.accordion-item.prc.skip-tee').length && activeStepNum === 4) {
                activeStepNum -= 1;
            }

            $('.onboard-wizard .wizard-current-step').text(activeStepNum + 1);
            activeStepContainer.removeClass('active-step');
            $('.accordion-item.team').addClass('active-step');
            $('.wizard-nav .next').text('All done!');
        } else {
            closeAccordionWrepper($(this));
            toasterMessage('Avatar was updated', 'success');
        }
        const section = $('.accordion-item.avatar');
        const avatarImageUrl = data.profile.avatar_image_url;
        const token = $('#change-personal-giving-goal input[name="authenticity_token"]').val();

        section.find('.tile-content .avatar-image.custom-preview').css('background-image', `url(${avatarImageUrl})`);
        section.find('.accordion-title').text('Profile Avatar: Your Custom Avatar Is Set');
        section.find('#change_avatar_image').html(
            `<div class="avatar-image custom-preview" style="background-image:url(${avatarImageUrl})" data-image-url="${avatarImageUrl}"></div>` +
            '<form action="/account" accept-charset="UTF-8" data-remote="true" method="post" id="delete_custom_profile_avatar">' +
            `<input type="hidden" name="_method" value="patch"><input type="hidden" name="authenticity_token" value="${token}">` +
            '<input type="hidden" name="profile[custom_avatar]" id="profile_custom_avatar">' +
            '<input type="submit" name="commit" value="Delete custom avatar image" data-confirm="Are you sure you want to delete your custom avatar image?" data-disable-with="Delete custom avatar image">' +
            '</form>');
    });

    $(document).on('ajax:error', '#profile_custom_avatar_form', function(e){
        e.preventDefault();
        let data = e.detail[0];

        console.log(data);
        toasterMessage(data.reason, 'error');
    });

    $(document).on('ajax:success', '#delete_custom_profile_avatar', function(e){
        e.preventDefault();
        let data = e.detail[0];

        toasterMessage('Avatar was deleted', 'success');

        const imgUrl = data.profile.avatar_image_url;
        const section = $('.accordion-item.avatar');
        const token = $('#change-personal-giving-goal input[name="authenticity_token"]').val();

        section.find('.accordion-title').text('Profile Avatar: You Are Currently Using A Basic Avatar');
        section.find('.tile-content .avatar-image.custom-preview').css('background-image', `url(${imgUrl})`);

        const $out = $(`
            <div class="avatar-type-select switches">
              <input type="checkbox" id="toggle-avatar" class="toggle-switcher avatar-switcher">
              <label for="toggle-avatar">
                <span></span>
                <span>Upload your own image</span>
              </label>
            </div>
              <div class="flex-slide-wrapper">
                <div class="avatar-slide slide-wrapper left closed">
                  <p>Upload your own image! We recommend a 500 pixel by 500 pixel square image.  (This will be cropped to a circle.)</p>
                  <p class="file-restrictions">Allowed file extentions: *.PNG, *.GIF, *.JPG. Max size 3mb</p>
                  <form id="profile_custom_avatar_form" enctype="multipart/form-data" action="/account" accept-charset="UTF-8" data-remote="true" method="post">
                    <input type="hidden" name="_method" value="patch"><input type="hidden" name="authenticity_token" value="${token}">
                    <div class="nice-file-wrapper">
                      <label class="choose_file_label" for="profile_custom_avatar">Choose file</label>
                      <input accept="image/png, image/gif, image/jpeg, image/jpg" id="profile_custom_avatar" data-direct-upload-url="${window.location.href}rails/active_storage/direct_uploads" type="file" name="profile[custom_avatar]"><br>
                      <span class="file-name"></span>
                    </div>
                    <input type="submit" name="commit" value="Upload now" class="hidden avatar-upload-now" data-disable-with="Upload now">
                  </form>
                </div>
                <div class="avatar-slide slide-wrapper right">
                  <p>Select one these pre-created avatar images:</p>
                  <form action="/account" id="predefined_avatars" accept-charset="UTF-8" data-remote="true" method="post"><input type="hidden" name="_method" value="patch">
                    <input type="hidden" name="authenticity_token" value="${token}">
                    <div class="avatar-image selectable ${imgUrl === 'https://4us.org/lifeguards/avatar-images/default.jpg' ? 'selected' : ''}" style="background-image:url(https://4us.org/lifeguards/avatar-images/default.jpg)" data-image-url="https://4us.org/lifeguards/avatar-images/default.jpg"></div>
                    <div class="avatar-image selectable ${imgUrl === 'https://4us.org/lifeguards/avatar-images/cat.jpg' ? 'selected' : ''}" style="background-image:url(https://4us.org/lifeguards/avatar-images/cat.jpg)" data-image-url="https://4us.org/lifeguards/avatar-images/cat.jpg"></div>
                    <div class="avatar-image selectable ${imgUrl === 'https://4us.org/lifeguards/avatar-images/frog.jpg' ? 'selected' : ''}" style="background-image:url(https://4us.org/lifeguards/avatar-images/frog.jpg)" data-image-url="https://4us.org/lifeguards/avatar-images/frog.jpg"></div>
                    <div class="avatar-image selectable ${imgUrl === 'https://4us.org/lifeguards/avatar-images/pineapple.jpg' ? 'selected' : ''}" style="background-image:url(https://4us.org/lifeguards/avatar-images/pineapple.jpg)" data-image-url="https://4us.org/lifeguards/avatar-images/pineapple.jpg"></div>
                    <div class="avatar-image selectable ${imgUrl === 'https://4us.org/lifeguards/avatar-images/dog.jpg' ? 'selected' : ''}" style="background-image:url(https://4us.org/lifeguards/avatar-images/dog.jpg)" data-image-url="https://4us.org/lifeguards/avatar-images/dog.jpg"></div>
                    <div class="avatar-image selectable ${imgUrl === 'https://4us.org/lifeguards/avatar-images/dog_with_glasses.jpg' ? 'selected' : ''}" style="background-image:url(https://4us.org/lifeguards/avatar-images/dog_with_glasses.jpg)" data-image-url="https://4us.org/lifeguards/avatar-images/dog_with_glasses.jpg"></div>
                    <div class="avatar-image selectable ${imgUrl === 'https://4us.org/lifeguards/avatar-images/unicorn.jpg' ? 'selected' : ''}" style="background-image:url(https://4us.org/lifeguards/avatar-images/unicorn.jpg)" data-image-url="https://4us.org/lifeguards/avatar-images/unicorn.jpg"></div>
                    <div class="avatar-image selectable ${imgUrl === 'https://4us.org/lifeguards/avatar-images/pig.jpg' ? 'selected' : ''}" style="background-image:url(https://4us.org/lifeguards/avatar-images/pig.jpg)" data-image-url="https://4us.org/lifeguards/avatar-images/pig.jpg"></div>
                    <div class="avatar-image selectable ${imgUrl === 'https://4us.org/lifeguards/avatar-images/child.jpg' ? 'selected' : ''}" style="background-image:url(https://4us.org/lifeguards/avatar-images/child.jpg)" data-image-url="https://4us.org/lifeguards/avatar-images/child.jpg"></div>
                    <br>
                    <input type="hidden" value="${imgUrl}" name="profile[avatar_image_url]" id="profile_avatar_image_url">
                    <input type="submit" name="commit" value="Save selection" data-disable-with="Save selection">
                  </form>
                </div>
              </div>`);

        const avatarImageUrl = data.profile.avatar_image_url;
        $('#predefined_avatars', $out).find(`[data-image-ulr="${avatarImageUrl}"]`).addClass('selected');

        section.find('#change_avatar_image').html($out);
    });

    $(document).on('ajax:error', '#delete_custom_profile_avatar', function(e){
        e.preventDefault();
        let data = e.detail[0];

        console.log(data);
        toasterMessage(data.reason, 'error');
    });

    $(document).on('ajax:success', '#predefined_avatars', function(e) {
        e.preventDefault();
        let data = e.detail[0];

        if ($('.sections-accordion.onboard-wizard').length) {
            const activeStepContainer = $('.accordion-item.active-step');
            let activeStepNum = activeStepContainer.data('step-num');

            if ($('.accordion-item.prc.skip-tee').length) {
                activeStepNum -= 1;
            }

            $('.onboard-wizard .wizard-current-step').text(activeStepNum + 1);
            activeStepContainer.removeClass('active-step');
            $('.accordion-item.team').addClass('active-step');
            $('.wizard-nav .next').text('All done!');
        } else {
            closeAccordionWrepper($(this));
            toasterMessage('Avatar was updated', 'success');

            const section = $('.accordion-item.avatar');
            const avatarImageUrl = data.profile.avatar_image_url;
            const token = $('#change-personal-giving-goal input[name="authenticity_token"]').val();

            section.find('.tile-content .avatar-image.custom-preview').css('background-image', `url(${avatarImageUrl})`);
        }
    });

    $(document).on('ajax:error', '#predefined_avatars', function(e){
        e.preventDefault();
        let data = e.detail[0];

        console.log(data);
        toasterMessage(data.reason, 'error');
    });

    $(document).on('click', '.accordion-action', function(e) {
        e.preventDefault();
        if ($(this).hasClass('active')) {
            $(this).removeClass('active');
            $(this)
                .siblings(".item-content")
                .slideUp(300);
            $(this).find('i')
                .removeClass("fa-minus")
                .addClass("fa-plus");
            const originalText = $(this).find('span').data('text');
            $(this).find('span').text(originalText);
        } else {
            $(".accordion-action i")
                .removeClass("fa-minus")
                .addClass("fa-plus");
            $(this)
                .find("i")
                .removeClass("fa-plus")
                .addClass("fa-minus");
            $(this).find('span').text('close');

            $('.accordion-action').removeClass("active");
            $(this).addClass("active");
            $(".item-content").slideUp(300);
            $(this)
                .siblings(".item-content")
                .slideDown(300);

            getBadgeAndRender();
        }
    });

    $(document).on('keyup change', '.money-format', function(e) {
        e.preventDefault();

        let value = $(this).val().replace(/[^0-9\.]/g, '').toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        $(this).val(`$${value}`);

    });

    $(document).on('click', '.change-avatar-image-button', function(e) {
        e.preventDefault();

        $change_avatar_image_button.hide();
        $change_avatar_section.show();
    });

    $(document).on('click', '#change_avatar_image .selectable', function(e) {
        e.preventDefault();

        const $clickedObj = $(e.currentTarget);
        $clickedObj
            .addClass('selected')
            .siblings()
            .removeClass('selected');

        $(`input[name="profile[avatar_image_url]"]`).val($clickedObj.data('image-url'));
    });

    $(document).on('click', '.change-team-goal-button', function(e) {
        e.preventDefault();

        $change_team_goal_button.hide();
        $change_team_goal_section.show();
    });

    $(document).on('click', '.invite-a-friend-button', function(e) {
        e.preventDefault();

        $invite_a_friend_button.hide();
        $invite_a_friend_section.show();
    });


    if ( $('#notice').text() ) {
        setTimeout(() => $('#notice').slideUp(200), 15000);
    }


    $(document).on('ajax:success', '#invite-a-friend', function(e) {
        e.preventDefault();
        const data = e.detail[0];

        toasterMessage(data.notice, 'success');
    });

    $(document).on('ajax:error', '#invite-a-friend', function(e) {
        const data = e.detail[0];

        toasterMessage(data.reason, 'error');
    });

    $(document).on('ajax:error', '#create-new-family-member', function (e){
        e.preventDefault();
        const data = e.detail[0];
        toasterMessage(data.reason, 'error');
    })

    $(document).on('ajax:success', '#change-personal-giving-goal', function(e) {
        e.preventDefault();
        const data = e.detail[0];

        if ( $('#toggle-giving').is(':checked') ) {
            const centerId = $('#change-personal-giving-goal #prc_id').val();
            if (!centerId) {
                toasterMessage('Please select a center to support or switch to "Give to where the need is the greatest"', 'error');

                return true;
            }
        }

        if ($('.sections-accordion.onboard-wizard').length) {
            const activeStepContainer = $('.accordion-item.active-step');
            let activeStepNum = activeStepContainer.data('step-num');

            if ($('.accordion-item.prc.skip-tee').length) {
                activeStepNum--;
            }

            $('.onboard-wizard .wizard-current-step').text(activeStepNum + 1);
            activeStepContainer.removeClass('active-step');
            $('.accordion-item.avatar').addClass('active-step');
        } else {
            closeAccordionWrepper($(this));
            toasterMessage(data.notice, 'success');

            const sectionTitle = $('.accordion-item.giving-goal h3');
            sectionTitle.find('i').remove();

            const value = data.badge.giving_goal_cents / 100;
            const formattedValue = (value).toString().replace(/[^0-9\.]/g, '').replace(/\B(?=(\d{3})+(?!\d))/g, ",");
            sectionTitle.find('b').text(`$${formattedValue}`);
            const prc = data.prc;
            $(this).closest('.accordion-item').find('.tile-main-text').html(`$${formattedValue}
                <div class="secondary-text">${!prc ? 'Give to where the need is the greatest.' : prc.name+'<br>('+prc.city+', '+prc.state+')'}</div>`);

            const thermometerElem = $('.personal-thermometer');
            const donation = thermometerElem.data('donation');
            thermometerElem.html([
                getThermometerHTML({
                    type: 'main',
                    goal: data.badge.giving_goal_cents / 100.0,
                    raised: donation / 100.0,
                    showNumbersAbove: true,
                    showPercentageInside: true,
                    delay: 400,
                    transitionTime: 3000,
                    daysRemaining: true,
                })
            ])
        }

        $('.accordion-item.merch').replaceWith(data.merch_content);
        if ( $('.sections-accordion.onboard-wizard').length) {
            $('.merch-congrats-modal').remove();
        }
    });

    $(document).on('ajax:error', '#change-personal-giving-goal', function(e) {
        e.preventDefault();
        let data = e.detail[0];

        toasterMessage(data.reason, 'error');
    });

    $(document).on('ajax:success', '#user-account-email', function(e){
        e.preventDefault();
        toasterMessage(e.detail[0].notice, 'success');
    })
    $(document).on('ajax:error', '#user-account-email', function(e){
        e.preventDefault();
        toasterMessage(e.detail[0].reason, 'error');
    })

    $(document).on('ajax:success', '#profile-connect-prc', function(e) {
        e.preventDefault();
        let data = e.detail[0];

        document.cookie = 'center_id=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;';

        $('#active-inactive').find('strong').remove();
        $('#active-inactive').find('br').remove();
        $('#active-inactive').find('a').show();
        $('#active-inactive').css('margin', 0);


        if ($('.sections-accordion.onboard-wizard').length) {
            const activeStepContainer = $('.accordion-item.active-step');
            const activeStepNum = activeStepContainer.data('step-num');

            $('.onboard-wizard .wizard-current-step').text(activeStepNum + 1);
            activeStepContainer.removeClass('active-step');
            $('.wizard-nav .next').before('<div class="prev">Back</div>');

            if (activeStepContainer.hasClass('skip-tee')) {
                $('.accordion-item.giving-goal').addClass('active-step');
            } else {
                $('.accordion-item.t-shirt').addClass('active-step');
            }
        } else {
            closeAccordionWrepper($(this));
            toasterMessage(data.notice, 'success');

            const goal = data.service_goal;
            const days = $('.personal-chart').data('served-days');
            const centerName = `${data.prc.name} (${data.prc.city}, ${data.prc.state})`;
            getCircleStat(goal, days);

            $('.personal-chart .days-goal').text(goal);
            $('.accordion-item.desktop-only .tile-main-text.short').text(`${goal} day${goal > 1? 's' : ''}`);
            $('.accordion-item.desktop-only .tile-title i').remove();
            $('.accordion-item.prc .tile-title i').remove();
            $('.accordion-item.prc .tile-main-text.long').text(centerName);

            if ($('.accordion-item.prc .secondary-text').length) {
                $('.accordion-item.prc .secondary-text').remove();
            }

            $('.accordion-item.team').replaceWith(data.team_content);
        }
    });

    $(document).on('ajax:error', '#profile-connect-prc', function(e) {
        e.preventDefault();
        let data = e.detail[0];

        toasterMessage(!$('#profile-connect-prc #prc_id').val() ? 'You should select a center to support' : data.reason, 'error');
    });


    $(document).on('ajax:success', '#change-team-goal', function(e) {
        e.preventDefault();
        const data = e.detail[0];

        toasterMessage(data.notice, 'success');

        const value = data.badge.giving_goal_cents / 100;
        const formattedValue = (value).toString().replace(/[^0-9\.]/g, '').replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        const teamGoal = $('.accordion-item .team-goals-aside .team-goal-set');
        const teamGoalTitle =$('.accordion-item.team h3');

        if ($('#team-connect-prc #prc_id').val()) teamGoalTitle.find('i').remove();

        teamGoal.find('i').remove();
        teamGoal.find('b').text(`$${formattedValue}`);


    });

    $(document).on('ajax:error', '#change-team-goal', function(e) {
        e.preventDefault();
        const data = e.detail[0];
        toasterMessage(data.reason, 'error');
    });

    $(document).on('ajax:success', '#request-service-day', function(e) {
        e.preventDefault();

        $(this).find('textarea').val('');
        $(this).find('.dates-list').empty();

        const data = e.detail[0];
        toasterMessage(data.notice, 'success');
    });

    $(document).on('ajax:error', '#request-service-day', function(e) {
        e.preventDefault();

        var data = e.detail[0];
        toasterMessage(data.reason, 'error');
    });

    $(document).on('ajax:success', '.social-fetch-form', function(e) {
        e.preventDefault();
        const data = e.detail[0];

        $('.social-notification .notification').text(data.notice).css('color', 'green');
        setTimeout(function(){
            location.reload();
        }, 1000);
    });

    $(document).on('ajax:error', '.social-fetch-form', function(e) {
        e.preventDefault();
        const data = e.detail[0];
        $('.social-notification .notification').text('Oops, something went wrong').css('color', 'red');
    });

    $(document).on('ajax:success', '.handle-social-post-form', function (e) {
        e.preventDefault();
        const status = e.detail[0].social_post.status;

        const form = $(this);
        const li = form.closest('.list-item');

        li.html(`<p class="status-message ${status}">Post status was successfully changed from pending to ${status}.</p>`);
        hideSocialNotice(li)
    });

    $(document).on('keyup keypress', '#request-tee', function(e){
        const keyCode = e.keyCode || e.which;
        if (keyCode === 13) {
            e.preventDefault();
            return false;
        }
    })


    $(document).on('ajax:success', '#request-tee', function(e){
        e.preventDefault();
        document.location.reload();
    });

    $(document).on('ajax:error', '#request-tee', function(e){
        e.preventDefault();
        let data = e.detail[0];

        toasterMessage(data.reason, 'error');
    });


    $(document).on('ajax:success', '#request-cap, #request-ls-tee', function(e){
        e.preventDefault();
        let data = e.detail[0];

        toasterMessage(data.notice, 'success');

        const form = $(this);
        form.closest('.goal-helper').find('.get-merch-reward').remove();
        form.closest('.merch__item').find('.checkbox-field').attr('checked', true);
        form.parent().removeClass('open');
        setTimeout(function () {
            form.parent().remove();
        }, 1000);

        if ( $('.merch-congrats-modal').length ) {
            const content = data.merch_content;
            $('.accordion-item.merch').replaceWith(content);
        }

        $('.merch-congrats-modal').remove();
    });

    $(document).on('ajax:error', '#request-cap, #request-ls-tee', function(e){
        e.preventDefault();
        let data = e.detail[0];

        toasterMessage(data.reason, 'error');
    });


    $(document).on('ajax:success', '#history-badge-requests', function(e) {
        e.preventDefault();
        let data = e.detail[0];

        closeAccordionWrepper($(this));
        $(this).closest('h3').slideUp(500);

        toasterMessage(data.notice, 'success');
        $(this).siblings('.tile-main-text').find('.secondary-text').text('Request was sent. We will be in touch with you shortly');
    });
    $(document).on('ajax:error', '#history-badge-requests', function(e){
        e.preventDefault();
        let data = e.detail[0];

        closeAccordionWrepper($(this));
        toasterMessage(data.reason, 'error');
    });

    function hideSocialNotice(li) {
        setTimeout(function(){
            li.slideUp(500);
        }, 10000);
    }

    $(document).on('click', '.add-new-prc-wrapper .my-community', function(e){
        e.preventDefault();
        const type = $(this).data('type');

        $('#prc-request-type').val(type);
        $('.add-new-prc-modal').show();
        $('.add-new-prc-modal .add-new-prc-wrapper').addClass('animated');
    });

    $(document).on('click', '.modal-close-wrapper', function(e){
        e.preventDefault();
        const $this = $(this);
        $this.closest('.animated').addClass('out');

        if ($('.modal-card-expired').length) {
            $('.modal-card-expired').hide();
        }

        setTimeout(function (){
            $this.closest('.add-new-prc-modal').hide();
            $this.closest('.animated').removeClass('out animated');
        }, 1100);
    })

    $(document).on('click', '.add-new-prc-wrapper .cancel-btn', function(e){
        e.preventDefault();
        $('.accordion-item.prc .add-new-prc-wrapper .my-community').slideDown(700);
        $('.accordion-item.prc #profile-connect-prc').slideDown(700);
        $('.accordion-item.prc .smart-form.open').slideDown(700);
        $('.accordion-item.prc .supported-center').slideDown(700);
        $('.accordion-item.prc .add-new-prc-wrapper .add-new-prc').slideUp(450);
    });

    $(document).on('click', '.giving-slide .orange-link-btn, .supported-center .orange-link-btn, .supported-center .orange-link, .prc-modal__close, .btn-change', function(e) {
        const type = $(this).data('type');

        togglePrcModal(type);
    });

    $(document).on('click', '.select-prc-center', function (e) {
        e.preventDefault();

        const type = $(this).data('type');
        const prcId = $(this).data('prc-id');
        const prcName = $(this).data('prc-pretty-name');

        if (type === 'giving') {
            $(`#change-personal-giving-goal #prc_id`).val(prcId).data('id', prcId);
        } else {
            $(`#${type}-connect-prc #prc_id`).val(prcId);
            $(`#${type}-connect-prc input[type=submit]`).removeClass('hidden');
        }

        $(`.supported-center.${type}-prc`).html(`You selected to support <a href="https://4us.org/adopt-a-center/prc-detail?prcId=${prcId}" class="highlighted" target="_blank">${cleanHtml(prcName)}</a> <span class="btn-change" data-type="${type}">Change</span>`)

        togglePrcModal(type);

        $('#current-center-id').val(prcId);

        $(`#${type}-prc-modal .center-tile.current`).removeClass('current');
        $(this).closest('.center-tile').addClass('current');
    });

    function togglePrcModal(type) {
        if ($(`#${type}-prc-modal`).hasClass('open')) {
            $(`#${type}-prc-modal`).addClass('out')
            setTimeout(function(){
                $(`#${type}-prc-modal`).removeClass('open')
            }, 1000)
        } else {
            $(`#${type}-prc-modal`).removeClass('out').addClass('open')
            const eTop = $(`#${type}-prc-modal`).closest('.accordion-content.animated .wrapper').offset().top;
            const windowSrollTop = $(window).scrollTop();

            $(`#${type}-prc-modal`).css('top', `-${eTop - windowSrollTop}px`);
        }
    }

    function cleanHtml(string) {
        const $re = /[<>;:\/\\\\?\!&#"'=]/gm;
        return string.replace($re, '');
    }

    $(document).on('click', '.form-control-wrapper-wide .sort-by', function(e){
        e.preventDefault();

        const $this = $(this);
        let order = $(this).data('order');

        $('.form-control-wrapper-wide .sort-by').each(function(index) {
            $(this).data('changed', '0');
            $(this).data('order', '');
            $(this).find('i').removeClass('fa-sort-down fa-sort-up').addClass('fa-sort');
        });

        if ( order === '' || order === 'desc' ) {
            order = 'asc';
            $this.find('i').removeClass('fa-sort fa-sort-up').addClass('fa-sort-down');
        } else if ( order === 'asc') {
            order = 'desc';
            $this.find('i').removeClass('fa-sort fa-sort-down').addClass('fa-sort-up');
        }

        $this.data('changed', '1').data('order', order);

        prc_search_sorting();
    });

    function getOrderValue() {
        let activeForm;
        if ($('.accordion-item.open-section').length) {
            activeForm = $('.accordion-item.open-section');
        } else if ( $('.accordion-item.active-step').length ) {
            activeForm = $('.accordion-item.active-step');
        } else if ( $('.accordion-action.active').length ) {
            activeForm = $('.accordion-action.active').closest('section.accordion-item');
        }

        const selectValue = activeForm.find('#prc-order-by option:selected').val();
        const orderName = activeForm.find('#sort-by-name');
        const orderState = activeForm.find('#sort-by-state');

        if ( orderName.data('changed') === '1' || orderState.data('changed') === '1' ) {
            if( orderName.data('changed') === '1' ) {
                return `${orderName.data('order-by')} ${orderName.data('order')}`;
            } else {
                return `${orderState.data('order-by')} ${orderState.data('order')}`;
            }
        }
        console.log(selectValue);
        return selectValue;
    }

    $(document).on('keyup', '#prc-search', debounce(prc_search_sorting, 750));
    $(document).on('change', '#prc-order-by', prc_search_sorting);

    function prc_search_sorting() {
        let activeSection;
        if ($('.accordion-item.open-section').length) {
            activeSection = $('.accordion-item.open-section');
        } else if ( $('.accordion-item.active-step').length ) {
            activeSection = $('.accordion-item.active-step');
        } else if ( $('.accordion-action.active').length ) {
            activeSection = $('.accordion-action.active').closest('section.accordion-item');
        }
        // const activeSearchForm = activeForm.find('.search-sorting-form #prc-search').val();

        const search = activeSection.find('#prc-search').val();
        const sort = getOrderValue();
        const currentPrcId = activeSection.find('#current-center-id').val() != ''
            ? activeSection.find('#current-center-id').val()
            : null;
        const profileID = $('#profile-id').val();
        const type = activeSection.data('type');

        showHideSpinner();

        const data = {
            search,
            sort: !sort.includes('state') ? sort : [sort, `city ${sort.split(' ')[1]}`],
            type,
        }
        $.ajax({
            method: 'GET',
            url: `/account/prcs`,
            data,
            success: function (data) {
                const centerList = activeSection.find('.prcs-list');
                const type = activeSection.find('.prc-modal__overlay').data('type')
                centerList.html('');
                if ( data.prcs.length > 0) {
                    data.prcs.forEach(function(prc) {
                        if (currentPrcId == prc.id) {
                            centerList.append(`
                        <div class="d-flex center-tile ${prc.id == currentPrcId && 'current'}">
                            <div class="name">${prc.name}</div>
                            <div class="d-flex location-action">
                                <div class="city-state">(${prc.city}, ${prc.state})</div>
                                <div class="center-action">
                                    <a class="select-prc-center" href="#" data-prc-id="${prc.id}" data-type="${type}" data-prc-pretty-name="${prc.name}(${prc.city}, ${prc.state})">Select</a>
                                </div>
                            </div>
                        </div>`);
                        } else {
                            centerList.append(`
                        <div class="d-flex center-tile">
                            <div class="name">${prc.name}</div>
                            <div class="d-flex location-action">
                                <div class="city-state">(${prc.city}, ${prc.state})</div>
                                <div class="center-action">
                                    <a class="select-prc-center" href="#" data-prc-id="${prc.id}" data-type="${type}" data-prc-pretty-name="${prc.name}(${prc.city}, ${prc.state})">Select</a>
                                </div>
                            </div>
                        </div>`);
                        }
                    })
                } else {
                    if ( $('.accordion-item.active-step').length ) {
                        centerList.append(`<div class="no-results"><p>We are sorry, we couldn't find any results for <b>"${search}"</b></p></div>`);
                    } else {
                        centerList.append(`<div class="no-results"><p>We are sorry, we couldn't find any results for <b>"${search}"</b></p>
                        <p>You can ask us to add a new center to support by filling this <a href="#" class="add-new-center-no-results">form</a>.</p>
                        <p>Center will appear in the list after moderation.</p></div>`);
                    }
                }

                showHideSpinner();
            }, error: function (xhr) {
                showHideSpinner();
                alert('Oops!');
            }
        });
    }

    function showHideSpinner() {
        $('#profile-prc-modal .loading').toggleClass('hidden').parent().toggleClass('no-scroll');
    }

    $(document).on('ajax:success', '#add-new-prc', function(e){
        let data = e.detail[0];
        const $this = $(this);

        toasterMessage(data.notice, 'success');

        $this.trigger('reset');
        $this.closest('.animated').addClass('out');
        setTimeout(function (){
            $this.closest('.add-new-prc-modal').hide();
            $this.closest('.animated').removeClass('out animated');
        }, 1100);
    });

    $(document).on('ajax:error', '#add-new-prc', function(e){
        const error = e.detail[0].reason;

        disableErrors();

        if ( typeof error === 'string' ) {
            toasterMessage(error, 'error');
        } else {
            for (let fieldName in error) {

                const input = $(`input[name="prc_request[${fieldName}]"]`);
                $(input).addClass('error-highlighted');

                const label = $(input).siblings('.d-block').find('label');
                $(label).text(`${label.text()} ${error[fieldName]}`).addClass('error-highlighted').siblings('span').hide();;
            }
        }
    });


    $(document).on('ajax:success', '#join-existing-team', function(e){
        e.preventDefault();
        const data = e.detail[0];

        if ($('.sections-accordion.onboard-wizard').length) {
            // document.location.reload();
            submitTShirtRequest();
        } else {
            toasterMessage(data.notice, 'success');
            $('.accordion-item.team').replaceWith(data.team_content.replace('class="accordion-item team"', 'class="accordion-item team open-section"'));
        }
    });

    $(document).on('ajax:error', '#join-existing-team', function(e){
        e.preventDefault();
        const error = e.detail[0].reason;

        toasterMessage(error, 'error');

    });

    $(document).on('ajax:success', '#create-new-team', function(e){
        e.preventDefault();
        const data = e.detail[0];

        if ($('.sections-accordion.onboard-wizard').length) {
            // document.location.reload();
            submitTShirtRequest();
        } else {
            toasterMessage(data.notice, 'success');
            $('.accordion-item.team').replaceWith(data.team_content.replace('class="accordion-item team"', 'class="accordion-item team open-section"'));
        }
    });

    $(document).on('ajax:error', '#create-new-team', function(e){
        e.preventDefault();
        const error = e.detail[0].reason;

        toasterMessage(error, 'error');
    });

    $(document).on('keyup change', '#add-new-prc input', function(e){
        e.preventDefault();

        $(this).removeClass('error-highlighted');
        const label = $(this).siblings('.d-block').find('label');
        $(label).text($(label).data('text')).removeClass('error-highlighted');
        $(this).siblings('.d-block').find('span').show();
    });

    $(document).on('click', '.add-new-center-no-results', function(e){
        e.preventDefault();
        // togglePrcModal('profile');

        $('.add-new-prc-wrapper .my-community').click();
    })

    $(document).on('submit', '.search-sorting-form', function(e){
        e.preventDefault();
    })

    $(document).on('click', '.disconnect-social', function(e) {
        e.preventDefault();
        const $this = $(this);
        const network = $this.data('social');
        $.ajax({
            type: 'POST',
            url: '/account-settings/disconnect_social_networks',
            data: {
                network,
            },
            success: function(data) {
                toasterMessage(data.notice, 'success');
                $this.closest('.social-network-action-link').remove();

                if ( !$('.social-network-action-link').length ) {
                    $('.social-network-section').remove();
                }
            },
            error: function(xhr) {
                console.log(xhr);
                toasterMessage(xhr.responseJSON.error, 'error');
            }
        })
    })

    $(document).on('click', '.tile-cta', function(e){
        e.preventDefault();

        const tileClass = $(this).data('class');
        const section = $(`.accordion-item.${tileClass}`);

        section.find('.item-content').show();
        section.addClass('open-section');
        section.find('.accordion-content').addClass('animated');



        getBadgeAndRender();
    })

    $(document).on('click', '.close-wrapper', function(e){
        e.preventDefault();
        closeAccordionWrepper($(this));
    })

    $(document).on('click', '.wizard-nav .next', function(e){
        e.preventDefault();
        const activeStepContainer = $('.accordion-item.active-step');
        let activeStepNum = activeStepContainer.data('step-num');
        switch (activeStepNum) {
            case 1:
                activeStepContainer.find('.active-step-form input[type=submit]').click();
                break;
            case 2:
                const result = document.getElementById('request-tee').reportValidity();
                if ( result ) {
                    $('.onboard-wizard .wizard-current-step').text(activeStepNum + 1);
                    activeStepContainer.removeClass('active-step').addClass('step-done');
                    $('.accordion-item.giving-goal').addClass('active-step');
                }
                break;
            case 3:
                const isFundraising = $('#is-fundraising').is(':checked');
                const givingGoal = $('#badge_giving_goal').val();

                if ($('.accordion-item.prc.skip-tee').length) {
                    activeStepNum--;
                }

                if (!isFundraising && !givingGoal) {
                    toasterMessage('Please, enter your $ giving goal or check the box that you will not fundraise', 'error');
                    return false;
                } else if (isFundraising) {
                    $('.onboard-wizard .wizard-current-step').text(activeStepNum + 1);
                    activeStepContainer.removeClass('active-step');
                    $('.accordion-item.avatar').addClass('active-step');
                    return true;
                }

                if (givingGoal) {
                    activeStepContainer.find('.active-step-form input[type=submit]').click();
                } else {
                    $('.onboard-wizard .wizard-current-step').text(activeStepNum + 1);
                    activeStepContainer.removeClass('active-step');
                    $('.accordion-item.avatar').addClass('active-step');
                }
                break;
            case 4 :
                if ($('.accordion-item.prc.skip-tee').length) {
                    activeStepNum -= 1;
                }

                if ($('#delete_custom_profile_avatar').length) {
                    $('.onboard-wizard .wizard-current-step').text(activeStepNum + 1);
                    activeStepContainer.removeClass('active-step');
                    $('.accordion-item.team').addClass('active-step');
                    $('.wizard-nav .next').text('All done!');
                } else {
                    if ( $('#toggle-avatar').is(':checked')) {
                        $('#profile_custom_avatar_form input[type="submit"]').click();
                    } else {
                        $('#predefined_avatars input[type="submit"]').click();
                    }
                }
                break;
            case 5:
                if ( $('#toggle-team').length ) {
                    if ($('#toggle-team').is(':checked')) {
                        if ($('#create-new-team #name').val() || $('#create-new-team #city').val() || $('#create-new-team #state').val()) {
                            $('#create-new-team input[type="submit"]').click();
                        } else {
                            submitTShirtRequest()
                        }
                    } else {
                        if ($('#team_id').val()) {
                            $('#join-existing-team input[type="submit"]').click();
                        } else {
                            submitTShirtRequest()
                        }
                    }
                } else {
                    submitTShirtRequest()
                }
                break;
            default: break;
        }
    });

    function submitTShirtRequest() {
        if ($('.accordion-item.prc.skip-tee').length) {

            $('#renew-membership').addClass('unfolding');
            return true;
        }

        $('#request-tee').find('#submit-request-t-shirt').click();
    }

    $(document).on('click', '.wizard-nav .prev', function(e) {
        e.preventDefault();
        const activeStepContainer = $('.accordion-item.active-step');
        let activeStepNum = activeStepContainer.data('step-num');

        if ($('.accordion-item.prc.skip-tee').length) {
            switch (activeStepNum) {
                case 5:
                case 4:
                    activeStepNum -= 1;
                    break;
                case 3:
                    $('.onboard-wizard .wizard-current-step').text(activeStepNum - 2);
                    activeStepContainer.removeClass('active-step');

                    $('.accordion-item.prc').addClass('active-step');
                    $('.wizard-nav .prev').remove();

                    return true;
                default: break
            }
        }

        $('.onboard-wizard .wizard-current-step').text(activeStepNum - 1);
        activeStepContainer.removeClass('active-step');

        switch ( activeStepContainer.data('step-num') ) {
            case 2:
                $('.accordion-item.prc').addClass('active-step');
                $('.wizard-nav .prev').remove();
                break;
            case 3:
                $('.accordion-item.t-shirt').addClass('active-step');
                break;
            case 4:
                $('.accordion-item.giving-goal').addClass('active-step');
                break;
            case 5:
                $('.direct-upload.direct-upload--complete').remove();
                $('#profile_custom_avatar').prop("disabled", false);
                $('.accordion-item.avatar').addClass('active-step');
                $('.wizard-nav .next').text('Next');
                break;
            default: break;
        }
    });

    $(document).on('ajax:success', '#team-connect-prc', function(e){
        e.preventDefault();

        const data = e.detail[0];

        toasterMessage(data.notice, 'success');

        $('.team-target .attention').remove();
        if ($('#change-team-goal .money-format').val()) $('.accordion-item.team h3').find('i').remove();
    });

    $(document).on('ajax:error', '#team-connect-prc', function(e) {
        e.preventDefault();
        const data = e.detail[0];

        toasterMessage(data.reason, 'error');
    });

    $(document).on('change', '.toggle-switcher', function(e){
        e.preventDefault();
        if ($(this).hasClass('avatar-switcher')) {
            $('.avatar-slide.slide-wrapper.left').toggleClass('closed');
            $('.avatar-slide.slide-wrapper.right').toggleClass('closed');
        } 
        if ($(this).hasClass('team-switcher')) {
            $('.team-slide.slide-wrapper.left').toggleClass('closed');
            $('.team-slide.slide-wrapper.right').toggleClass('closed');
        }
        if ($(this).hasClass('giving-switcher')) {
            $('.giving-slide.slide-wrapper.left').toggleClass('closed');
            $('.giving-slide.slide-wrapper.right').toggleClass('closed');

            if ( !$(this).is(':checked') ) {
                const id = $(`#change-personal-giving-goal #prc_id`).val();
                $(`#change-personal-giving-goal #prc_id`).val('').data('id', id);
            } else {
                const id = $(`#change-personal-giving-goal #prc_id`).data('id');
                console.log(id);
                $(`#change-personal-giving-goal #prc_id`).val(id).data('id', '');
            }
        }
    });

    $(document).on('click', '#profile_custom_avatar_form input[type="submit"]', function(e){
        if( !$('#profile_custom_avatar').get(0).files[0] ) {
            toasterMessage('Please, select a file', 'error');
        }
    })

    $(document).on('click', '.merch-set-goal', function(e){
        e.preventDefault();

        const goal = $(this).data('goal');
        closeAccordionWrepper($(this));

        if ( window.innerWidth >= 800 ) {
            $('.accordion-item.giving-goal .tile-cta').click();
        } else {
            $('.accordion-item.giving-goal .accordion-action').click();
            $([document.documentElement, document.body]).animate({
                scrollTop: $('.accordion-item.giving-goal').offset().top
            }, 1000);
        }
        $('#badge_giving_goal').val(goal).change();
    });

    $(document).on('click', '.get-merch-reward', function(e){
        e.preventDefault();

        $(this).siblings('.request-merch-wrapper').addClass('open');
    })
    $(document).on('click', '.close-merch-request', function(e){
        e.preventDefault();

        if ( $(this).data('congrats') ) {
            if ($('#do-not-show-again').prop('checked') == true) {
                const cookieDate = new Date;
                cookieDate.setFullYear(cookieDate.getFullYear() +1);
                document.cookie = `is_show_congrats_merch=${true}; expires=${cookieDate.toGMTString()};`;
            }
            $(this).closest('.merch-congrats-modal').remove();
        } else {
            $(this).closest('.request-merch-wrapper').removeClass('open');
        }
    })
    $(document).on('click', '.close-merch-congrats', function(e){
        e.preventDefault();
        if ($('#do-not-show-again').prop('checked') == true) {
            const cookieDate = new Date;
            cookieDate.setFullYear(cookieDate.getFullYear() +1);
            document.cookie = `is_show_congrats_merch=${true}; expires=${cookieDate.toGMTString()};`;
        }
        $(this).closest('.merch-congrats-modal').remove();
    })

    if (window.location.pathname === '/users/sign_up' && window.location.search) {
        const centerId = window.location.search.split('=')[1];
        const cookieDate = new Date;
        cookieDate.setDate(cookieDate.getDate() + 14);
        document.cookie = `center_id=${centerId}; expires=${cookieDate.toGMTString()}; path=/`;
    }

    $(document).on('ajax:success', '#profile-body-content-form', function(e){
        e.preventDefault();
        let data = e.detail[0];

        closeAccordionWrepper($(this));
        $(this).closest('h3').slideUp(500);

        toasterMessage(data.notice, 'success');
    });
    $(document).on('ajax:error', '#profile-body-content-form', function(e){
        e.preventDefault();
        const data = e.detail[0];

        toasterMessage(data.reason, 'error');
    });

    $(document).on('ajax:success', '#team-body-content-form', function(e){
        e.preventDefault();
        let data = e.detail[0];

        closeAccordionWrepper($(this));
        $(this).closest('h3').slideUp(500);

        toasterMessage(data.notice, 'success');
    });
    $(document).on('ajax:error', '#team-body-content-form', function(e){
        e.preventDefault();
        const data = e.detail[0];

        toasterMessage(data.reason, 'error');
    });

    $(document).on('change', '#badge-by-year', function(e){
        e.preventDefault();

        const badgeId = $(this).find('option:selected').val();
        getBadgeById(badgeId);
    });

    $(document).on('click', '.add-more-service-date', function(e){
        e.preventDefault();
        $(this).text('Select date');
        $(this).siblings('.dates-list').append('<li class="date-item new-date-item" style="display: none">\n' +
            '   <span class="input-scroll-hack">' +
            '       <input name="service_request[dates][]" class="service-day-date" placeholder="Select date" required="required" readonly="readonly" type="text" id="service_request_dates">' +
            '       <span></span>' +
            '   </span>\n' +
            '   <span class="remove-service-date"><i class="fas fa-trash-alt"></i></span>\n' +
            '</li>');
        setTimeout(function(){
            const disDates = $('.service-dates-wrapper .dates-list').data('dates');
            let disabledDates = [];
            for ( let i = 0; i < disDates.length; i++ ) {
                const [mm, dd, yyyy] = disDates[i].split('/');
                disabledDates.push(`${mm}/${dd}/${yyyy}`);
            }
            $( ".dates-list .date-item .service-day-date" ).each(function( index ) {
                if ($(this).val()) {
                    disabledDates.push($(this).val());
                }
            });

            $('.date-item.new-date-item .service-day-date').datetimepicker({
                timepicker: false,
                disabledDates: disabledDates,
                format: 'm/d/Y',
                formatDate: 'm/d/Y',
                maxDate: 0,
            });
            $('.new-date-item').show('900').removeClass('new-date-item');
        }, 10);
    });

    $(document).on('click', '.input-scroll-hack span', function (e) {
        e.preventDefault();
        $(this).siblings('.service-day-date').datetimepicker('show');
    });

    $(document).on('click', '.remove-service-date', function(e){
        $(this).parent().remove();
    });

    $(document).on('click', '.donation-assignment .edit', function(e){
        e.preventDefault();
        const donationId = $(this).data('donation-id');
        $(`#donation-id-${donationId}-assignment`).addClass('unfolding');
    })
    $(document).on('click', '.donation-assignment .edit-next-amount', function(e){
        e.preventDefault();
        const donationId = $(this).data('donation-id');
        $(`#donation-id-${donationId}-assignment-edit`).addClass('unfolding');
    })

    $(document).on('click', '.close-donation-assignment', function(e){
        e.preventDefault();
        const modal = $(this).closest('.general-modal-container');
        modal.addClass('out');
        setTimeout(function () {
            modal.removeClass('out unfolding')
        }, 1500);
    });
    $(document).on('keyup change', '.donation-edit-assignments.unfolding .next-assignments li input', function(e) {
        e.preventDefault();

        let newDonationAmount = 0.0;
        $('.donation-edit-assignments.unfolding .next-assignments li input').each(function(item){
            const newAmount = $(this).val();
            newDonationAmount = parseFloat(newDonationAmount) + parseFloat(newAmount);
        });

        $('.donation-edit-assignments.unfolding .donation-amount-text').text(`$${newDonationAmount.toFixed(2)}`);
    });

    $(document).on('change', '.donation-assignment-select.for-what', function(){
        const select = $(this);
        const type = select.val();
        const form = select.closest('.donation-edit-form');
        const index = select.closest('.assignments').data('index');
        const targetContainer = select.closest('.assignments').find('.donation-targets-container');
        const targetSelect = targetContainer.find('.donation-targets-list');

        if (type === '4us') {
            targetSelect.val(null);

            targetContainer.css('display', 'none');
            return true;
        }

        if (type === '') return true;

        const data = $(`#${type}-json`).data('select-data').replace( /:id=>/gm, '"id": ').replace(/:url=>/g, '"url": ').replace(/:name=>/gm, '"name": ');
        const parsedData = JSON.parse(data);
        if (parsedData) {
            let selectBox = `<li class="donation-targets-container"><label for="donation_donation_assignments_attributes_${index}_target_id">Name</label><select class="donation-assignment-select donation-targets-list" name="donation[donation_assignments_attributes][${index}][target_id]">`;

            parsedData.forEach(function(item){
                selectBox += `<option value="${item.id}">${item.name}</option>`;
            });
            selectBox += '</select></li>';

            targetContainer.replaceWith(selectBox);


            setTimeout(function(){
                select.closest('.assignments').find('.donation-targets-list').selectize({
                    onDropdownOpen: function() {
                        selectizeMobile(select);
                        select.closest('.assignments').find('.donation-targets-container').find('.selectize-input.items .remove-single').click()
                    },
                    plugins: ['remove_button'],
                });
            }, 10)
        }
    })

    $(document).on('click', '.remove-assignment', function(e){
        e.preventDefault();
        $(this).siblings('.hidden-remove-assignment').val(true);
        $(this).closest('.assignments').hide();
    });

    $(document).on('click', '.new-assignment', function(e){
        e.preventDefault();

        const modal = $(this).closest('.modal');
        const assignments = modal.find('.assignments');
        // assignmentIndex
        const ai = assignments.length ? assignments.last().data('index') + 1 : 0;

        const $out = $(`
            <ul class="assignments" data-index="${ai}">
                <li>
                    <label for="donation_donation_assignments_attributes_${ai}_amount_cents">Amount </label>
                    <input step="0.01" min="0" autocomplete="false" type="number" value="0.00" name="donation[donation_assignments_attributes][${ai}][amount]" id="donation_donation_assignments_attributes_${ai}_amount">
                </li>
                <li>
                    <label for="donation_donation_assignments_attributes_${ai}_for_what">For what</label>
                    <select class="donation-assignment-select for-what" name="donation[donation_assignments_attributes][${ai}][for_what]" id="donation_donation_assignments_attributes_${ai}_for_what">
                        <option value="4us" selected>Give to where the need is the greatest</option>
                        <option value="profile">Lifeguards individual</option>
                        <option value="team">Lifeguards team</option>
                        <option value="prc">Pregnancy Center</option>
                        <option value="church">Church</option>
                    </select>
                </li>
                <li class="donation-targets-container"></li>
                <li class="btn-li">
                    <input class="hidden-remove-assignment" type="hidden" value="false" name="donation[donation_assignments_attributes][${ai}][_destroy]" id="donation_donation_assignments_attributes_${ai}__destroy">
                    <button name="button" type="submit" class="remove-assignment">Remove</button>
                </li>
            </ul>`);

        modal.find('.form-buttons').before($out);

        const select = modal.find('.donation-edit-form .assignments:last-of-type .donation-assignment-select');
        select.selectize({
            onDropdownOpen: function() {
                selectizeMobile(select);
                select.closest('li').find('.selectize-input.items .remove-single').click()
            },
            plugins: ['remove_button'],
        })
    })
    $(document).on('ajax:success', '.donation-edit-form-assignment', function(e){
        e.preventDefault();

        const data = e.detail[0];
        toasterMessage(data.notice, 'success');

        $(this).closest('.modal').find('.close-donation-assignment').click();
        const donationId = $(this).attr('id').split('-')[2];
        const donationContainer = $(`li#donate-id-${donationId}`);
        const occurrencyCount = $(this).find('#donation_occurrence_count').val();
        const willRecurAt = $(this).find('#donation_will_recur_at').val();
        const periodicuty = $(this).find('input[name="donation[periodicity]"]:checked').val();
        donationContainer.find('.recurrence-count-remaining').remove();

        if (occurrencyCount > 1 || !occurrencyCount === '') {
            const occurrencyCountHtml = `<div class="recurrence-count-remaining">
                              <span class="Count"><span class="medium">Recurrence count remaining:</span> ${occurrencyCount}</span>
                          </div>`;

            donationContainer.find('.edit-btns-wrapper').before(occurrencyCountHtml);
        }

        if (willRecurAt) {
            donationContainer.find('.edit-next-amount').text('Edit next donation amount');
            donationContainer.find('.donation-type .medium').text('This is recurring donation');
        } else {
            $(this).find('input[type="radio"]:checked').prop('checked', false);
            $(this).find('#donation_occurrence_count').val('');
            donationContainer.find('.edit-next-amount').text('Convert to recurring donation');
            donationContainer.find('.donation-type .medium').text('This is one time donation');
            donationContainer.find('.recurrence-count-remaining').remove();
        }
    });
    $(document).on('ajax:error', '.donation-edit-form-assignment', function(e){
        e.preventDefault();

        let reason = e.detail[0].reason;
        console.log(reason)
        if (reason === 'Donation assignments cant be blank')
            reason = 'Please, select your donation_assignments';

        toasterMessage(reason, 'error');

        $(this).closest('.modal').find('.close-donation-assignment').click();
    });

    $(document).on('ajax:success', '.donation-edit-form', function(e){
        e.preventDefault();
        const $this = $(this);
        const formId = $(this).attr('id');
        const editNextDonationForm = $(`#${formId}-edit`);

        $(this).find('.assignments').each(function(index) {
            const i = $(this).data('index');
            $(this).remove();
            $this.find(`#donation_donation_assignments_attributes_${i}_id`).remove();
        });

        editNextDonationForm.find('.assignments').each(function(index) {
            const i = $(this).data('index');
            $(this).remove();
            editNextDonationForm.find(`#donation_donation_assignments_attributes_${i}_id`).remove();
        });

        const data = e.detail[0];
        const response = data.assignments;


        let newHtml = '';
        let nextDonation = '';
        let donorIndex = 0;
        $.each(response, function(index, value) {
            const amount = (response[index]['amount_cents'] / 100).toFixed(2);

            newHtml += `<ul class="assignments" data-index="${donorIndex}">
                      <li>
                        <label for="donation_donation_assignments_attributes_${donorIndex}_amount_cents">Amount</label>
                        <input step="0.01" min="0" autocomplete="false" type="number" value="${amount}" name="donation[donation_assignments_attributes][${donorIndex}][amount]" id="donation_donation_assignments_attributes_${donorIndex}_amount">
                      </li>
                      <li>
                        <label for="donation_donation_assignments_attributes_${donorIndex}_for_what">For what</label>
                        <select class="donation-assignment-select for-what selectized" data-index="${donorIndex}" name="donation[donation_assignments_attributes][${donorIndex}][for_what]" id="donation_donation_assignments_attributes_${donorIndex}_for_what" tabindex="-1">
                            <option value="4us" ${response[index]['for_what'] === '4us' ? 'selected' : ''}>Give to where the need is the greatest</option>
                            <option value="profile" ${response[index]['for_what'] === 'profile' ? 'selected' : ''}>Lifeguards individual</option>
                            <option value="team" ${response[index]['for_what'] === 'team' ? 'selected' : ''}>Lifeguards team</option>
                            <option value="prc" ${response[index]['for_what'] === 'prc' ? 'selected' : ''}>Pregnancy Center</option>
                            <option value="church" ${response[index]['for_what'] === 'church' ? 'selected' : ''}>Church</option>
                        </select>
                      </li>
                      <li class="donation-targets-container">`;
                        switch (response[index]['for_what']) {
                            case '4us':
                                newHtml += ``
                                break;
                            case 'profile':
                                newHtml += `<label for="donation_donation_assignments_attributes_{$donorIndex}_target_id">Name</label>
                                    <select class="donation-assignment-select donation-targets-list" data-index="${donorIndex}" name="donation[donation_assignments_attributes][${donorIndex}][target_id]" id="donation_donation_assignments_attributes_${donorIndex}_target_id">`;
                                newHtml += getSelectOptions('profile', response[index]['target_id']);
                                newHtml += `</select>`;
                                break;
                            case 'team':
                                newHtml += `<label for="donation_donation_assignments_attributes_{$donorIndex}_target_id">Name</label>
                                    <select class="donation-assignment-select donation-targets-list" data-index="${donorIndex}" name="donation[donation_assignments_attributes][${donorIndex}][target_id]" id="donation_donation_assignments_attributes_${donorIndex}_target_id">`;
                                newHtml += getSelectOptions('team', response[index]['target_id']);
                                newHtml += `</select>`;
                                break;
                            case 'prc':
                                newHtml += `<label for="donation_donation_assignments_attributes_{$donorIndex}_target_id">Name</label>
                                    <select class="donation-assignment-select donation-targets-list" data-index="${donorIndex}" name="donation[donation_assignments_attributes][${donorIndex}][target_id]" id="donation_donation_assignments_attributes_${donorIndex}_target_id">`;
                                newHtml += getSelectOptions('prc', response[index]['target_id']);
                                newHtml += `</select>`;
                                break;
                            case 'church':
                                newHtml += `<label for="donation_donation_assignments_attributes_{$donorIndex}_target_id">Name</label>
                                    <select class="donation-assignment-select donation-targets-list" data-index="${donorIndex}" name="donation[donation_assignments_attributes][${donorIndex}][target_id]" id="donation_donation_assignments_attributes_${donorIndex}_target_id">`;
                                newHtml += getSelectOptions('church', response[index]['target_id']);
                                newHtml += `</select>`;
                                break;
                            default:
                                break;
                        }
            newHtml += `</li>
                      <li class="btn-li">
                        <input class="hidden-remove-assignment" type="hidden" value="false" name="donation[donation_assignments_attributes][${donorIndex}][_destroy]" id="donation_donation_assignments_attributes_${donorIndex}__destroy">
                        <button name="button" type="submit" class="remove-assignment">Remove</button>
                      </li>
                    </ul>
                    <input type="hidden" value="${index}" name="donation[donation_assignments_attributes][${donorIndex}][id]" id="donation_donation_assignments_attributes_${donorIndex}_id">`

            nextDonation += `<ul class="assignments next-assignments" data-index="${donorIndex}">
                      <li>
                        <label for="donation_donation_assignments_attributes_${donorIndex}_next_amount_cents">${response[index]['target_pretty_name']}</label>
                        <input step="0.01" min="1" autocomplete="false" type="number" value="${response[index]['amount_cents'] / 100.0}" name="donation[donation_assignments_attributes][${donorIndex}][next_amount_cents]" id="donation_donation_assignments_attributes_${donorIndex}_next_amount_cents">
                      </li>
                    </ul>
                    <input type="hidden" value="${index}" name="donation[donation_assignments_attributes][${donorIndex}][id]" id="donation_donation_assignments_attributes_${donorIndex}_id">`

            donorIndex++;
        });

        $this.find('h4').after(newHtml);
        $.each( $this.find('li select'), function(item){
            const selectBox = $(this);
            selectBox.selectize({
                onDropdownOpen: function() {
                    selectizeMobile(selectBox);
                    selectBox.closest('li').find('.selectize-input.items .remove-single').click()
                },
                plugins: ['remove_button'],
            })
        })


        editNextDonationForm.find('h4').after(nextDonation);

        toasterMessage(data.notice, 'success');

        $this.closest('.modal').find('.close-donation-assignment').click();

        const donationId = e.target.id.split('-')[2]
        const donationContainer = $(`#donate-id-${donationId}`);
        const typeContainer = donationContainer.find('.type');
        const assignments = $(`#donations-id-${donationId}`).find('.assignments');

        if (assignments.length) {
            let $out = '<div class="medium">Give to: <div class="f-dir-column">';
            $(assignments).each(function(i){
                $out += `<div>${$(assignments[i]).find('.for-what option:selected').text()}${$(assignments[i]).find('.donation-targets-list option:selected').text() ? ' - ' + $(assignments[i]).find('.donation-targets-list option:selected').text() : ''}</div>`;
            })
            $out += '</div></div>';
            console.log($out);
            $(typeContainer).html($out);
        } else {
            let $out = '<div class="medium">Give to: <div class="f-dir-column">';
            $(assignments).each(function(i){
                $out += `<div>Give to where the need is the greatest</div>`;
            })
            $out += '</div></div>';
            $(typeContainer).html($out);
        }
    })

    $(document).on('ajax:error', '.donation-edit-form', function(e) {
        e.preventDefault();
        let reason = e.detail[0].reason;
        if (reason === 'Donation assignments cant be blank')
            reason = 'Please, select your donation assignments';
        toasterMessage(reason, 'error');
    });

    $(document).on('ajax:success', '#family-member-update', function (e) {
        e.preventDefault();
        const notice = e.detail[0].notice;
        toasterMessage(notice, 'success');
        closeAccordionWrepper($(this));
    });

    $(document).on('ajax:error', '#family-member-update', function (e) {
        e.preventDefault();
        const reason = e.detail[0].reason;
        toasterMessage(reason, 'error');
    });

    $(document).on('click', '.remove-cc', function(e){
        e.preventDefault();

        showSpinner($(this).closest('.cc-container'));
        removeCCAjax($(this).data('cc-id'), $(this).data('customer-id'));
        //
        // if ($('.cc-list .cc-container').length === 1) {
        //     const promptText = '<div>' +
        //             `<p>If you remove your card, your recurring donation will stop. Are you sure you'd like to remove it?</p>`+
        //             `<button type="button" id="remove-cc-ok" class="btn btn-primary" data-customer-id="${$(this).data('customer-id')}" data-cc-id="${$(this).data('cc-id')}">Remove</button>` +
        //             '<button type="button" id="remove-cc-close" class="btn">Close</button>' +
        //         '</div>';
        //
        //     toastr.warning(promptText, 'Remove Card', {
        //         "closeButton": false,
        //         "debug": false,
        //         "newestOnTop": false,
        //         "progressBar": false,
        //         "positionClass": "toast-top-center",
        //         "preventDuplicates": false,
        //         "showDuration": "300",
        //         "hideDuration": "1000",
        //         "timeOut": "15000",
        //         "extendedTimeOut": "15000",
        //         "showEasing": "swing",
        //         "hideEasing": "linear",
        //         "showMethod": "fadeIn",
        //         "hideMethod": "fadeOut",
        //         "onHidden": function(){
        //             $('.show-spinner').removeClass('show-spinner').find('.spinner').remove();
        //         },
        //     });
        // } else {
        //     removeCCAjax($(this).data('cc-id'), $(this).data('customer-id'));
        // }
    });

    $(document).on('click', '#remove-cc-ok', function(e){
        e.preventDefault();

        removeCCAjax($(this).data('cc-id'), $(this).data('customer-id'));
    })

    $(document).on('ajax:success', '#donor-payment-info', function(e){
        e.preventDefault();
        toasterMessage(e.detail[0].notice, 'success');
    });
    $(document).on('ajax:error', '#donor-payment-info', function(e){
        e.preventDefault();
        toasterMessage(e.detail[0].reason, 'error');
    });

    $(document).on('click', '.clear-date-input', function(e) {
        e.preventDefault();
        $(this).parent().find('.donation-will-recur-at').val('');
        $(this).hide();
    })

    $(document).on('click', '.review-my-cards', function(e){
        e.preventDefault();
        $(this).closest('.modal-card-expired-wrapper').find('.modal-close-wrapper').click();
        $('#manage-cards-label').click();
        if ($( window ).width() < 800 ) {
            $([document.documentElement, document.body]).animate({
                scrollTop: $('.accordion-item.donor-payment').offset().top
            }, 1000);
        }
        setTimeout(function(){
            if ($( window ).width() >= 800 ) {
                $('.accordion-item.donor-payment .tile-cta').click();
            } else {
                $('.accordion-item.donor-payment .accordion-action').click();
            }
        }, 600)
    });
});

function getSelectOptions(type, targetId) {
    const data = $(`#${type}-json`).data('select-data').replace( /:id=>/gm, '"id": ').replace(/:url=>/g, '"url": ').replace(/:name=>/gm, '"name": ');
    const parsedData = JSON.parse(data);
    let options = '';
    if (parsedData) {
        parsedData.forEach(function(item){
            options += `<option value="${item.id}" ${item.id === targetId ? 'selected' : ''}>${item.name}</option>`;
        });
    }

    return options;
}

function removeCCAjax(ccId, customerId){
    const container = $(`#cc-${ccId}`);

    $.ajax({
        type: 'POST',
        url: `/donor_delete_cc/?cc_id=${ccId}&customer_id=${customerId}`,
        success: function(data) {
            container.slideUp(1000);
            toasterMessage(data.notice, 'success');

            if ($('.cc-list .cc-container').length === 1) {
                container.closest('section').find('p').remove();
                container.parent().after('<p style="text-align: center; display: none">Oops! Looks like you don\'t have linked cards yet</p>');
                container.closest('section').find('p').slideDown(1000);
            }

            setTimeout(function () {
                container.remove();
            }, 1500);
        },
        error: function(xhr) {
            console.log(xhr);
            container.removeClass('show-spinner').find('.spinner').remove();
            toasterMessage(xhr.responseJSON.error, 'error');
        }
    })
};

function showSpinner(container) {
    const spinner = $(`<div class="spinner"><i class="fas fa-spinner fa-pulse"></div>`);
    container.addClass('show-spinner').append(spinner);
}

function getBadgeById(id) {
    $.ajax({
        type: 'GET',
        url: `/badges/${id}`,
        success: function(data) {
            const badge = data.badge;
            const donationCents = badge.donations_cents;
            const container = $('.thermometer-chart.stat');
            const thermometer = container.find('.personal-thermometer');
            thermometer.data('goal', badge.giving_goal_cents ? badge.giving_goal_cents : 0);
            thermometer.data('donation', donationCents ? donationCents : 0);
            const chart = container.find('.personal-chart');
            chart.data('service-goal', badge.service_goal ? badge.service_goal : 0 );
            chart.data('served-days', badge.served_days ? badge.served_days : 0);
            chart.find('.days').html(`<b>${badge.served_days}</b> of <b>${badge.service_goal}</b> day${badge.service_goal > 1 ? 's' : ''} served`)

            renderPersonalStat(container.find('.personal-thermometer'), container.find('.personal-chart'), false, '-stat');

            $('.supported-centers').remove();
            const centerLinkBase = 'https://4us.org/adopt-a-center/prc-detail?prcId=';
            let centerOutput = '';
            centerOutput += '<div class="supported-centers">';

            if (badge.giving_prc)  centerOutput += `<div>You supported <a href="${centerLinkBase}${badge.giving_prc.id}" target="_blank">${badge.giving_prc.name}</a> for fundraising</div>`;
            if (badge.service_prc)  centerOutput += `<div>You supported <a href="${centerLinkBase}${badge.service_prc.id}" target="_blank">${badge.service_prc.name}</a> for service</div>`;
            centerOutput += '</div>';
            container.append(centerOutput);
        },
        error: function(xhr) {
            toasterMessage('Oops! Something went wrong', 'error');
            $('.stat-content').html('Oops, something went wrong. Please <a href="mailto:lifeguards@4us.org?subject=profile%20statistics%20not%20working">contact us</a> so we can help you. Thanks.')
            return false
        }
    })
}

function getBadgeAndRender() {
    if ( $('.thermometer-chart.stat').length ) {
        setTimeout(function(){
            const badgeId = $('.thermometer-chart.stat').data('badge-id');
            getBadgeById(badgeId);
        }, 100);
    }
}

function closeAccordionWrepper(elem) {
    $(elem).closest('.accordion-content').addClass('out');

    setTimeout(function () {
        $(elem).closest('.wrapper').find('.item-content').hide();
        $(elem).closest('.accordion-item').removeClass('open-section');
        $('body').removeClass('no-y-scroll');

        const actionBtn = $(elem).closest('.accordion-item').find('.accordion-action');
        actionBtn.find('span').text( actionBtn.find('span').data('text'));
        actionBtn.find('i').removeClass('fa-minus').addClass('fa-plus');
        actionBtn.removeClass('active');
        $(elem).closest('.accordion-content').removeClass('out');
    }, 1100);

}

function toasterMessage(text, type) {
    const options = {
        "closeButton": false,
        "debug": false,
        "newestOnTop": false,
        "progressBar": true,
        "positionClass": "toast-top-center",
        "preventDuplicates": false,
        "onclick": null,
        "showDuration": "300",
        "hideDuration": "1000",
        "timeOut": "5000",
        "extendedTimeOut": "1000",
        "showEasing": "swing",
        "hideEasing": "linear",
        "showMethod": "fadeIn",
        "hideMethod": "fadeOut",
    };

    if (type === 'error') {
        toastr.error(text, '', options);
    } else {
        toastr.success(text, '', options);
    }
}

function disableErrors() {
    const fieldNames = [
        'center_address',
        'center_city',
        'center_name',
        'center_state',
        'center_website',
        'contact_age',
        'contact_city',
        'contact_email',
        'contact_first_name',
        'contact_last_name',
        'contact_phone_number',
        'contact_state',
    ];
    $.each(fieldNames, function (i, fieldName) {
        const input = $(`input[name="prc_request[${fieldName}]"]`);
        $(input).removeClass('error-highlighted');

        const label = input.siblings('.d-block').find('label');
        $(label).text(label.data('text')).removeClass('error-highlighted').siblings('span').show();
    })
}
function silentRemoveCard(elem, data) {
    const cardContainer = elem.closest('.cc-container ');


    const ccId = cardContainer.find('.remove-cc').data('cc-id');
    const customerId = cardContainer.find('.remove-cc').data('customer-id');
    $.ajax({
        type: 'POST',
        url: `/donor_delete_cc/?cc_id=${ccId}&customer_id=${customerId}&silent=true`,
        success: function (data) {
        },
        error: function (xhr) {
            console.log(xhr);
            container.removeClass('show-spinner').find('.spinner').remove();
            toasterMessage(xhr.responseJSON.error, 'error');
        }
    })

    cardContainer.attr('id', `cc-${data.card.id}`);
    cardContainer.find('.remove-cc').data('cc-id', data.card.id);
    cardContainer.find('.cc-last4').text(data.card.last4);
    cardContainer.find('.cc-expiration').text(`Expiration date: ${data.card.exp_month}/${data.card.exp_year}`);
    $('.add-new-cc-container').slideUp();
}

function selectizeMobile($this) {
    const windowWidth = $(window).width();
    if (windowWidth < 768 && $('.donation-edit.unfolding').length) {
        const container = $this.closest('.modal-background .modal > div');
        const elem = $this.closest('li').find('.selectize-control.donation-assignment-select');
        console.log(container.offset().top);
        console.log(elem.offset().top);
        console.log(container.offset().top - elem.offset().top);
        console.log((container.offset().top - elem.offset().top) * -1);
        container.animate({
            scrollTop: (container.offset().top - elem.offset().top) * -1
        });
    }

    return true
}

window.onload=function(){
    const hamburgerToggle = document.querySelector("#hamburger-toggle");
    const navbar = document.querySelector("#navbar");
    hamburgerToggle.addEventListener("click", onHamburgerClick);
    function onHamburgerClick() {
        if (!navbar.classList.contains("open")) {
            navbar.classList.add("open");
        }
        else {
            navbar.classList.remove("open");
        }
    }
}   